/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SciGen } from './sci.gen.model';
import { TypeBoxGen } from './type-box.gen.model';
import { EquipementGen } from './equipement.gen.model';
import { StatutBoxGen } from './statut-box.gen.model';
import { NiveauGen } from './niveau.gen.model';
import { PositionPorteGen } from './position-porte.gen.model';
import { SiteGen } from './site.gen.model';


export interface BoxGen { 
    actif?: boolean;
    cadenas?: string;
    contentieux?: boolean;
    created: Date;
    createdBy: string;
    dtype?: string;
    hauteur?: number;
    hauteurPorte?: number;
    id?: number;
    identifiant?: string;
    instanceLabel: string;
    largeur?: number;
    largeurPorte?: number;
    listEquipement?: Array<EquipementGen>;
    listNumeroPuce?: Array<string>;
    longueur?: number;
    niveau?: NiveauGen;
    positionPorte?: PositionPorteGen;
    sci: SciGen;
    site: SiteGen;
    statut?: StatutBoxGen;
    surface?: number;
    tarifHt?: number;
    typeBox?: TypeBoxGen;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
    volume?: number;
}

export class BoxGen_ {
  static readonly actif = 'actif';
  readonly actif = 'actif';
  static readonly cadenas = 'cadenas';
  readonly cadenas = 'cadenas';
  static readonly contentieux = 'contentieux';
  readonly contentieux = 'contentieux';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly hauteur = 'hauteur';
  readonly hauteur = 'hauteur';
  static readonly hauteurPorte = 'hauteurPorte';
  readonly hauteurPorte = 'hauteurPorte';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly identifiant = 'identifiant';
  readonly identifiant = 'identifiant';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly largeur = 'largeur';
  readonly largeur = 'largeur';
  static readonly largeurPorte = 'largeurPorte';
  readonly largeurPorte = 'largeurPorte';
  static readonly listEquipement = 'listEquipement';
  readonly listEquipement = 'listEquipement';
  static readonly listNumeroPuce = 'listNumeroPuce';
  readonly listNumeroPuce = 'listNumeroPuce';
  static readonly longueur = 'longueur';
  readonly longueur = 'longueur';
  static readonly niveau = 'niveau';
  readonly niveau = 'niveau';
  static readonly positionPorte = 'positionPorte';
  readonly positionPorte = 'positionPorte';
  static readonly sci = 'sci';
  readonly sci = 'sci';
  static readonly site = 'site';
  readonly site = 'site';
  static readonly statut = 'statut';
  readonly statut = 'statut';
  static readonly surface = 'surface';
  readonly surface = 'surface';
  static readonly tarifHt = 'tarifHt';
  readonly tarifHt = 'tarifHt';
  static readonly typeBox = 'typeBox';
  readonly typeBox = 'typeBox';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static readonly volume = 'volume';
  readonly volume = 'volume';
  static fields() {
    return new BoxGen_();
  }
}

