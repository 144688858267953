import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';

import {AppComponent} from './app.component';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NavigationComponent} from './theme/layout/admin/navigation/navigation.component';
import {NavContentComponent} from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavGroupComponent} from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import {NavCollapseComponent} from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import {NavItemComponent} from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import {NavBarComponent} from './theme/layout/admin/nav-bar/nav-bar.component';
import {NavLeftComponent} from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import {NavSearchComponent} from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import {NavRightComponent} from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ConfigurationComponent} from './theme/layout/admin/configuration/configuration.component';

import localeFr from '@angular/common/locales/fr';

/* Menu Items */
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import {NgbDateParserFormatter, NgbDropdownModule, NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {registerLocaleData} from '@angular/common';
import {AuthGuard} from './guards/auth.guard';
import {AutorisationGuard} from './guards/autorisation.guard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorService} from './shared/ascore/interceptor/http-interceptor.service';
import {AsCoreModule} from './shared/ascore/ascore.module';
import {NotFoundComponent} from './home/maintenance/not-found.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {CustomDateParserFormatter} from './custom-date-parser.formatter';
import {MonCompteComponent} from './home/mon-compte/mon-compte.component';
import {AppConfigService} from './shared/ascore/app-config.service';
import {AsCoreConfiguration} from './shared/generated';
import {UtilisateurModule} from './administration/referentiel/utilisateur/utilisateur.module';
import {AngularSvgIconModule} from "angular-svg-icon";
import {SignatureModalComponent} from './home/signature-modal/signature-modal.component';
import {AngularSignaturePadModule} from "@almothafar/angular-signature-pad";

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    NotFoundComponent,
    MonCompteComponent,
    SignatureModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    AsCoreModule.forRoot(),
    HttpClientModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbNavModule,
    NgSelectModule,
    UtilisateurModule,
    AngularSignaturePadModule,
    AngularSvgIconModule.forRoot()
  ],
  providers: [
    NavigationItem,
    AuthGuard,
    AutorisationGuard,
    {provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrap,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: AsCoreConfiguration,
      useFactory: (_: any) => new AsCoreConfiguration(
        {
          basePath: location.origin
        }
      ),
      multi: false
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function bootstrap(appConfigService: AppConfigService) {
  return () => appConfigService.loadConf();
}
