/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrBoxPojoGen { 
    actif?: boolean;
    boxTarifTtc?: number;
    clientInstanceLabel?: string;
    contentieux?: boolean;
    hauteur?: number;
    hauteurPorte?: number;
    id?: number;
    identifiant?: string;
    instanceLabel?: string;
    largeur?: number;
    largeurPorte?: number;
    longueur?: number;
    niveauId?: number;
    niveauInstanceLabel?: string;
    sciId?: number;
    sciInstanceLabel?: string;
    siteId?: number;
    siteInstanceLabel?: string;
    statutBoxId?: string;
    statutBoxInstanceLabel?: string;
    surface?: number;
    tarifHt?: number;
    typeBoxId?: string;
    typeBoxInstanceLabel?: string;
    volume?: number;
}

export class SrBoxPojoGen_ {
  static readonly actif = 'actif';
  readonly actif = 'actif';
  static readonly boxTarifTtc = 'boxTarifTtc';
  readonly boxTarifTtc = 'boxTarifTtc';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly contentieux = 'contentieux';
  readonly contentieux = 'contentieux';
  static readonly hauteur = 'hauteur';
  readonly hauteur = 'hauteur';
  static readonly hauteurPorte = 'hauteurPorte';
  readonly hauteurPorte = 'hauteurPorte';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly identifiant = 'identifiant';
  readonly identifiant = 'identifiant';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly largeur = 'largeur';
  readonly largeur = 'largeur';
  static readonly largeurPorte = 'largeurPorte';
  readonly largeurPorte = 'largeurPorte';
  static readonly longueur = 'longueur';
  readonly longueur = 'longueur';
  static readonly niveauId = 'niveauId';
  readonly niveauId = 'niveauId';
  static readonly niveauInstanceLabel = 'niveauInstanceLabel';
  readonly niveauInstanceLabel = 'niveauInstanceLabel';
  static readonly sciId = 'sciId';
  readonly sciId = 'sciId';
  static readonly sciInstanceLabel = 'sciInstanceLabel';
  readonly sciInstanceLabel = 'sciInstanceLabel';
  static readonly siteId = 'siteId';
  readonly siteId = 'siteId';
  static readonly siteInstanceLabel = 'siteInstanceLabel';
  readonly siteInstanceLabel = 'siteInstanceLabel';
  static readonly statutBoxId = 'statutBoxId';
  readonly statutBoxId = 'statutBoxId';
  static readonly statutBoxInstanceLabel = 'statutBoxInstanceLabel';
  readonly statutBoxInstanceLabel = 'statutBoxInstanceLabel';
  static readonly surface = 'surface';
  readonly surface = 'surface';
  static readonly tarifHt = 'tarifHt';
  readonly tarifHt = 'tarifHt';
  static readonly typeBoxId = 'typeBoxId';
  readonly typeBoxId = 'typeBoxId';
  static readonly typeBoxInstanceLabel = 'typeBoxInstanceLabel';
  readonly typeBoxInstanceLabel = 'typeBoxInstanceLabel';
  static readonly volume = 'volume';
  readonly volume = 'volume';
  static fields() {
    return new SrBoxPojoGen_();
  }
}

